import { Input } from 'antd'
import { useEffect } from 'react'
import { FormLabel } from './FormLabel'
import { CRUD_VIEW_TYPE } from '../../Types'
import { FormDataType } from './FormTypes'
import { UseFormReturn } from 'react-hook-form'
import get from 'lodash/get'

/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 06/04/2022.
 */

type Props = {
  data: FormDataType
  formHook: UseFormReturn
  viewType: CRUD_VIEW_TYPE
}

//const prefix = 'https://'
const postfix = '.cinema-ad.com'
export const FormDomaineField = ({ data, formHook, viewType }: Props) => {
  const {
    register,
    setValue,
    getValues,
    unregister,
    formState: { errors },
  } = formHook

  /*  if (data.id === 'registration.headline') {
    console.log('FormTextField > errors = ', errors)
    const k = get(errors, data.id)
    console.log('FormTextField > k = ', k)
  }*/

  const errorState = get(errors, data.id)
  console.log('FormDomaineField > errorState = ', errorState)
  const errorMessage: string =
    errorState && errorState.message ? (errorState.message as string) : (data.errorMsg as string)

  useEffect(() => {
    register(data.id, data.validation)

    return () => {
      unregister(data.id)
    }
  }, [register, unregister, data.id, data.validation])

  useEffect(() => {
    if (data.value) {
      setValue(data.id, data.value)
    }
  }, [data.value, data.id, data.validation, register, setValue])

  const onChange = (value) => {
    setValue(data.id, value.target.value)
  }

  const currentValue = getValues(data.id) ? getValues(data.id) : data.value

  //const maybeDisabled = data.disabled ? data.disabled[viewType] : false

  return (
    <div className="col-12 w-100 py-2">
      <FormLabel label={data.label}>
        {data.postfix ? (
          <p>
            {data.label} <span className="opacity-75 p-small">{data.postfix}</span>
          </p>
        ) : null}
      </FormLabel>

      <Input
        addonBefore="http://"
        addonAfter={postfix}
        defaultValue={currentValue}
        onChange={onChange}
        placeholder={data.placeholder}
        status={errorState ? 'error' : ''}
      />
      {/*<Input
          placeholder={data.placeholder}
          onChange={onChange}
          defaultValue={currentValue}
          status={errorState ? 'error' : ''}
          disabled={currentValue && maybeDisabled ? true : false}
        />*/}

      {errorState ? <div className="p-small text-danger">{errorMessage}</div> : null}
    </div>
  )
}
