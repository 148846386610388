/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 12/04/2023.
 */
import React from 'react'
import { Link, Route, Routes } from 'react-router-dom'
import { Button } from 'antd'
import { useMeProjects } from '../../hooks/useMeProjects'
import { PageHeader } from '../../components/display/PageHeader'
import { DrawerContainer } from '../../components/drawer/DrawerContainer'
import { ProjectCRUD } from './ProjectCRUD'

//TODO: When you get props pass them, uncommented to avoid empty object pattern
//type Props = {}

export const ProjectList = () => {
  const { data, mutate } = useMeProjects()
  console.log('ProjectList > darta = ', data)
  return (
    <>
      <Routes>
        <Route
          path="create"
          element={
            <>
              <DrawerContainer projectId="" title="Create project">
                <ProjectCRUD mutateProjectList={mutate}></ProjectCRUD>
              </DrawerContainer>
            </>
          }
        />
        <Route path="/:projectId" element={<h1>project id </h1>} />
      </Routes>

      <div className="container px-4 mt-4">
        <PageHeader title="Project list">
          <Link to="create">
            <Button type="primary">Create project +</Button>
          </Link>
        </PageHeader>
        <div className="row" style={{}}>
          {data.length === 0 ? (
            <div className="col-12 bg-white py-4 px-4 border align-items-center d-flex ">
              <div className="text-center" style={{ width: '100%' }}>
                <h6 className="mt-2 opacity-75">No projects found</h6>
              </div>
            </div>
          ) : null}
          {data.map((project, index) => {
            return (
              <div key={index} className="col-12 bg-white py-4 px-4 border align-items-center d-flex ">
                <div className="" style={{ width: '35%' }}>
                  <p className="p-small opacity-75">Project</p>
                  <p className="fw-bold">{project.title}</p>
                </div>
                <div className="" style={{ width: '45%' }}>
                  <p className="p-small  opacity-75">Url</p>
                  <p className="">{project.url}.cinema-ad.com</p>
                </div>
                <div className="d-flex justify-content-end" style={{ width: '25%' }}>
                  <Link to={'/project/' + project._id}>
                    <Button>View</Button>
                  </Link>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
