/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 09/12/2022.
 */
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import Button from 'antd/lib/button'

import Alert from 'antd/lib/alert'
import { omit } from 'lodash'
import { post } from '../../service/API'
import { API_URL } from '../../constants'
import { getProject } from './projectConfig'
import { populateConfig } from '../../util/populateConfig'
import { getFormType } from '../../components/form/formFactory'

import { openNotification } from '../../components/notificationToast'
import { useProjectById } from '../../hooks/useProjectById'
import { ENDPOINTS } from '../../config/endpoints'

type Props = {
  closeDrawer?: (value) => void
  onClose?: () => void
  viewType?: 'CREATE' | 'UPDATE' | 'VIEW'
  mutateProjectList?: () => void
}

const CREATE_URL = API_URL + '/admin/projects'

export const ProjectCRUD = ({ onClose, closeDrawer, viewType = 'CREATE', mutateProjectList }: Props) => {
  const { projectId } = useParams() as { projectId: string }
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { data, mutate } = useProjectById(projectId)
  //  console.log('ProjectCRUD > data = ', data)

  const configObj = viewType === 'UPDATE' ? populateConfig(getProject(), data) : getProject()

  const formHook = useForm()

  const { handleSubmit } = formHook
  const [errorState, setErrorState] = useState<any>()

  const onSubmit = (formData, e) => {
    e.preventDefault()

    console.log(formData.start instanceof Date)
    const createData = { ...formData }

    const updatedData = { ...data, ...formData, projectId: projectId }

    const method = viewType === 'UPDATE' ? 'PUT' : 'POST'
    const sendData = viewType === 'UPDATE' ? updatedData : createData

    const omittedObj = omit(sendData, ['inviteSent'])
    const updatedSendData = { ...omittedObj }

    //
    // notice that we are using the same endpoint for both create and update
    // this is only for the side drawer, with the project details like market title etc, not the text for the pages.
    // The backend shares the same endpoint for both create and update of the project details and the text for pages
    //
    const path = viewType === 'UPDATE' ? ENDPOINTS.ADMIN.PROJECTS.updateSettings(projectId) : CREATE_URL

    setIsLoading(true)
    return post(path, updatedSendData, method)
      .then((result) => {
        openNotification('Updated', result.data ? result.data.message : 'Campaign updated', 'happy')
        mutate()
        setIsLoading(false)
        if (mutateProjectList) {
          mutateProjectList()
        }

        if (onClose) {
          onClose()
        }

        if (closeDrawer) {
          closeDrawer(false)
          //setAddSiteDrawer(false)
        }
      })
      .catch((error) => {
        setIsLoading(false)
        console.log('error = ', error)
        setErrorState(error)
      })
  }

  const onError = (err, e) => {
    e.preventDefault()
  }

  const navigateBack = () => {
    if (onClose) {
      console.log('-- > navigateBack = ')
      onClose()
    }
  }

  return (
    <>
      {viewType === 'CREATE' || viewType === 'UPDATE' ? (
        <form className="row" onSubmit={handleSubmit(onSubmit, onError)}>
          {configObj.map((item, index) => {
            const comp = getFormType(item, index, formHook)
            return comp
          })}

          <div className="col-12 mt-3">
            {errorState ? (
              <Alert showIcon message={errorState.type} description={errorState.message} type="error" />
            ) : (
              <></>
            )}
          </div>

          <div className="col-12 mb-4"></div>

          <div className="col-12 d-flex justify-content-end mt-3">
            <Button className="me-2" onClick={navigateBack}>
              Cancel
            </Button>
            <Button type="primary" loading={isLoading} onClick={handleSubmit(onSubmit, onError)}>
              {viewType === 'CREATE' ? 'Create project' : 'Save changes'}
            </Button>
          </div>
        </form>
      ) : (
        <div className="">
          <h1>IS NOT CREATE OR UPDATE</h1>
        </div>
      )}
    </>
  )
}
