import { ACCEPTED_FILE_TYPES } from '../../constants'

export const getProject = () => {
  return projectConfig
}

export const getProjectCampaign = () => {
  return projectCampaign
}

const projectCampaign = [
  {
    id: 'prizeImage',
    type: 'FILE',
    placeholder: 'Prize image',
    label: 'Upload prize image',
    value: '',
    validation: { required: true },
    errorMsg: 'This field is required ',
    className: 'col-6',
  },
  {
    id: 'logo',
    type: 'FILE',
    placeholder: 'Logo',
    label: 'Upload logo',
    value: '',
    validation: { required: true },
    errorMsg: 'This field is required ',
    className: 'col-6',
  },
  {
    id: 'bgImage',
    type: 'FILE',
    placeholder: 'Background',
    label: 'Upload Background Image',
    hint: 'Please select an image in .png format',
    acceptedFileTypes: ACCEPTED_FILE_TYPES,
    value: '',
    validation: { required: true },
    errorMsg: 'This field is required ',
    className: 'col-6',
    disabled: true,
  },
  {
    id: 'wofImage',
    type: 'FILE',
    placeholder: 'Wheel of fortune',
    label: 'Upload Wheel of fortune Image',
    hint: 'Please select an image in .png format',
    acceptedFileTypes: ACCEPTED_FILE_TYPES,
    value: '',
    validation: { required: true },
    errorMsg: 'This field is required ',
    className: 'col-6',
    disabled: true,
  },

  {
    id: 'wheel.primaryColor',
    type: 'COLOR',
    placeholder: 'Primary color',
    label: 'primaryColor',
    value: '',
    validation: { required: true },
    errorMsg: 'This field is required ',
    disabled: true,
  },
  {
    id: 'wheel.secondaryColor',
    type: 'COLOR',
    placeholder: '-',
    label: 'Secondary color',
    value: '',
    validation: { required: true },
    errorMsg: 'This field is required ',
    disabled: true,
  },
  {
    id: 'wheel.ringColor',
    type: 'COLOR',
    placeholder: 'ring color',
    label: 'ring color',
    value: '',
    validation: { required: true },
    errorMsg: 'This field is required ',
    disabled: true,
  },

  {
    id: 'page.headline',
    type: 'TEXT',
    placeholder: 'headline',
    label: 'Headline',
    value: '',
    validation: { required: true },
    errorMsg: 'This field is required ',
    disabled: true,
  },

  {
    id: 'page.body',
    type: 'TEXT',
    placeholder: 'Body text',
    label: 'Body text',
    value: '',
    validation: { required: true },
    errorMsg: 'This field is required ',
    disabled: true,
  },

  {
    type: 'SECTION',
    label: 'Welcome page',
  },
  {
    id: 'page.prize',
    type: 'TEXT',
    placeholder: 'The prize text',
    label: 'Prize text',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
    disabled: true,
  },

  {
    id: 'page.prizeDescription',
    type: 'TEXT',
    placeholder: 'prize Description text',
    label: 'Prize description text',
    value: '',
    validation: { required: true },
    errorMsg: 'This field is required ',
    disabled: true,
  },

  {
    id: 'page.startBtn',
    type: 'TEXT',
    placeholder: 'Text on start button',
    label: 'Start button text',
    value: '',
    validation: { required: true },
    errorMsg: 'This field is required ',
    disabled: true,
  },

  {
    id: 'page.spinBtn',
    type: 'TEXT',
    placeholder: 'Text on spin button',
    label: 'Spin button text',
    value: '',
    validation: { required: true },
    errorMsg: 'This field is required ',
    disabled: true,
  },
  {
    id: 'page.seePrizeBtn',
    type: 'TEXT',
    placeholder: 'seePrizeButton',
    label: 'See prize button',
    value: '',
    validation: { required: true },
    errorMsg: 'This field is required ',
    disabled: true,
  },

  {
    type: 'SECTION',
    label: 'Spin the wheel page',
  },
  {
    id: 'spinPage.winHeadline',
    type: 'TEXT',
    placeholder: 'Winning headline',
    label: 'Winning headline',
    value: '',
    validation: { required: true },
    errorMsg: 'This field is required ',
    disabled: true,
  },
  {
    id: 'spinPage.winBody',
    type: 'TEXT',
    placeholder: 'when user wins body text',
    label: 'Winning body text',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
    disabled: true,
  },
  {
    id: 'spinPage.spinBtn',
    type: 'TEXT',
    placeholder: 'when user wins body text',
    label: 'Spin button text',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
    disabled: true,
  },
  {
    id: 'spinPage.winBtn',
    type: 'TEXT',
    placeholder: 'When the user has won',
    label: 'Win btn - Shown when the user has won',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
    disabled: true,
  },
  {
    id: 'spinPage.retrySpinBtn',
    type: 'TEXT',
    placeholder: 'When user needs to spin again',
    label: 'Retry spin button text',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
    disabled: true,
  },

  {
    type: 'SECTION',
    label: 'Register page',
  },

  {
    id: 'registration.headline',
    type: 'TEXT',
    placeholder: 'Register headline',
    label: 'Register headline',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
    disabled: true,
  },

  {
    id: 'registration.description',
    type: 'TEXT',
    placeholder: 'description on registration page',
    label: 'Register description',
    value: '',
    validation: { required: true },
    errorMsg: 'This field is required ',
    disabled: true,
  },
  {
    id: 'registration.submitBtn',
    type: 'TEXT',
    placeholder: 'submit Button ',
    label: 'Register submit Button',
    value: '',
    validation: { required: true },
    errorMsg: 'This field is required ',
    disabled: true,
  },
  {
    id: 'registration.checkBoxOne',
    type: 'TEXT',
    placeholder: 'checkbox one text',
    label: 'Checkbox 1',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
    disabled: true,
  },

  {
    id: 'registration.errorCheckbox',
    type: 'TEXT',
    placeholder: 'Error text',
    label: 'Checkbox error text',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
    disabled: true,
  },

  {
    id: 'registration.termsHeadline',
    type: 'TEXT',
    placeholder: 'Terms popup headline',
    label: 'Terms popup headline',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
    disabled: true,
  },
  {
    id: 'registration.terms',
    type: 'TEXTAREA',
    placeholder: 'General terms and condition',
    label: 'Terms popup body text ',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
    disabled: true,
  },

  {
    type: 'SECTION',
    label: 'Form',
  },

  {
    id: 'registration.formFirstName',
    type: 'TEXT',
    placeholder: 'Form first name',
    label: 'Form first name',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
    disabled: true,
  },
  {
    id: 'registration.errorFormFirstName',
    type: 'TEXT',
    placeholder: 'Error text',
    label: 'Error first name',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
    disabled: true,
  },
  {
    id: 'registration.formLastName',
    type: 'TEXT',
    placeholder: 'Form last name',
    label: 'Form last name',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
    disabled: true,
  },

  {
    id: 'registration.errorFormLastName',
    type: 'TEXT',
    placeholder: 'Error text',
    label: 'Error last name',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
    disabled: true,
  },

  {
    id: 'registration.formEmail',
    type: 'TEXT',
    placeholder: 'Form email',
    label: 'Form email',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
    disabled: true,
  },
  {
    id: 'registration.errorFormEmail',
    type: 'TEXT',
    placeholder: 'Error text',
    label: 'Error email',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
    disabled: true,
  },
  {
    id: 'registration.dropDownTitle',
    type: 'TEXT',
    placeholder: 'Drop down Title',
    label: 'Dropdown title',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
    disabled: true,
  },
  {
    id: 'registration.dropDownOptionOne',
    type: 'TEXT',
    placeholder: 'Option 1',
    label: 'Option 1',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
    disabled: true,
  },
  {
    id: 'registration.dropDownOptionTwo',
    type: 'TEXT',
    placeholder: 'Option 2',
    label: 'Option 2',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
    disabled: true,
  },
  {
    id: 'registration.dropDownOptionThree',
    type: 'TEXT',
    placeholder: 'Option 3',
    label: 'Option 3',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
    disabled: true,
  },
  {
    id: 'registration.dropDownOptionFour',
    type: 'TEXT',
    placeholder: 'Option 4',
    label: 'Option 4',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
    disabled: true,
  },
  {
    id: 'registration.dropDownOptionFive',
    type: 'TEXT',
    placeholder: 'Option 5',
    label: 'Option 5',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
    disabled: true,
  },
  {
    id: 'registration.dropDownOptionSix',
    type: 'TEXT',
    placeholder: 'Option 6',
    label: 'Option 6',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
    disabled: true,
  },

  {
    id: 'registration.errorDropDown',
    type: 'TEXT',
    placeholder: 'Error text',
    label: 'Error dropdown',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
    disabled: true,
  },

  {
    id: 'registration.checkBoxTwo',
    type: 'TEXTAREA',
    placeholder: 'checkbox two text',
    label: 'Checkbox 2 - links can be added in text {{ http://google.com }}',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
    disabled: true,
  },
  {
    id: 'registration.checkBoxTwoLink',
    type: 'TEXT',
    placeholder: 'link',
    label: 'Checkbox 2 link',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
    disabled: true,
  },
  {
    id: 'registration.errorCheckboxTwo',
    type: 'TEXT',
    placeholder: 'Error text',
    label: 'Checkbox 2 error',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
    disabled: true,
  },

  {
    type: 'SECTION',
    label: 'Endpage',
  },

  {
    id: 'endPage.headline',
    type: 'TEXT',
    placeholder: 'Tillykke',
    label: 'Endpage headline ',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
    disabled: true,
  },
  {
    id: 'endPage.body',
    type: 'TEXT',
    placeholder: 'Endpage body',
    label: 'Body text',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
    disabled: true,
  },

  {
    id: 'endPage.prizeTxt',
    type: 'TEXT',
    placeholder: 'The prize is big and beautiful',
    label: 'Prize text',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
    disabled: true,
  },

  {
    id: 'endPage.btn',
    type: 'TEXT',
    placeholder: 'google.dk',
    label: 'Button text',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
    disabled: true,
  },

  {
    id: 'endPage.link',
    type: 'TEXT',
    placeholder: 'the actual link https://google.dk',
    label: 'Button link',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
    disabled: true,
  },

  {
    type: 'SECTION',
    label: 'Footer',
  },

  {
    id: 'footer.headline',
    type: 'TEXT',
    placeholder: 'Headline',
    label: 'Footer headline',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
    disabled: true,
  },

  {
    id: 'footer.body',
    type: 'TEXT',
    placeholder: 'Footer body',
    label: 'Footer body',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
    disabled: true,
  },
  {
    id: 'footer.footerBtn',
    type: 'TEXT',
    placeholder: 'Button text',
    label: 'Footer button text',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
  },
  {
    id: 'footer.link',
    type: 'TEXT',
    placeholder: 'Button link',
    label: 'Footer link',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
  },

  {
    id: 'links.facebook',
    type: 'TEXT',
    placeholder: 'Link to facebook',
    label: 'facebook',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
  },
  {
    id: 'links.youtube',
    type: 'TEXT',
    placeholder: 'Link to facebook',
    label: 'youtube',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
  },
  {
    id: 'links.instagram',
    type: 'TEXT',
    placeholder: 'Link to facebook',
    label: 'instagram',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
  },
  {
    id: 'links.website',
    type: 'TEXT',
    placeholder: 'Link to website',
    label: 'website',
    value: '',
    validation: { required: false },
    errorMsg: 'This field is required ',
  },
]

const projectConfig = [
  {
    id: 'title',
    type: 'TEXT',
    placeholder: 'Add project name',
    label: 'Project name',
    value: '',
    validation: { required: true },
    errorMsg: 'This field is required ',
  },
  {
    id: 'client',
    type: 'TEXT',
    placeholder: 'Company name',
    label: 'Client name (tv2, Coca cola, etc)',
    value: '',
    validation: { required: true },
    errorMsg: 'This field is required ',
    disabled: true,
  },

  {
    id: 'market',
    type: 'TEXT',
    placeholder: '',
    label: 'Select market',
    value: '',
    validation: { required: true },

    disabled: true,
  },

  {
    id: 'url',
    type: 'DOMAINE',
    placeholder: 'url ',
    label: 'URL (currently not in use)',
    value: '',
    validation: { required: true },
    errorMsg: 'This field is required ',
  },
  {
    id: 'start',
    type: 'DATE',
    placeholder: '',
    label: 'Start date',
    value: new Date(),
    validation: { required: true },
    className: 'col-6',
    errorMsg: 'This field is required ',
  },
  {
    id: 'end',
    type: 'DATE',
    placeholder: '',
    label: 'End date',
    value: null,
    validation: { required: true },
    className: 'col-6',
    errorMsg: 'This field is required ',
  },
]
