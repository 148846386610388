/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 24/10/2023.
 */
import React, { Suspense } from 'react'
import { Route, Routes, useParams } from 'react-router-dom'
import { DrawerContainer } from '../../components/drawer/DrawerContainer'
import { ProjectCRUD } from './ProjectCRUD'
import { ProjectAttachClient } from './ProjectAttachClient'
import { ProjectView } from './ProjectView'
import { ParticipantsPage } from './ParticipantsPage'
import { SpinnerFullHeight } from '../../components/spinner/SpinnerFullHeight'

export const ProjectPage = () => {
  const { projectId } = useParams() as { projectId: string }

  return (
    <>
      <Routes>
        <Route
          path="edit-details"
          element={
            <>
              <DrawerContainer projectId={projectId} title="Edit project">
                <ProjectCRUD viewType="UPDATE"></ProjectCRUD>
              </DrawerContainer>
            </>
          }
        />
        <Route
          path="add-user"
          element={
            <>
              <DrawerContainer projectId={projectId} title="Add user">
                <ProjectAttachClient />
              </DrawerContainer>
            </>
          }
        />
        <Route
          path="/participants"
          element={
            <Suspense fallback={<SpinnerFullHeight />}>
              <ParticipantsPage />
            </Suspense>
          }
        />
        <Route path="/" element={<ProjectView></ProjectView>} />
      </Routes>
    </>
  )
}
