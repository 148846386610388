/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 01/10/2021.
 */
import React, { useState } from 'react'
import './header.scss'
import { NavLink, useParams } from 'react-router-dom'
import { HEADER_ROUTES } from '../../config/routes'
import { COLOR_PRIMARY } from '../../constants'
import { Button, Popconfirm } from 'antd'
import { ENDPOINTS } from '../../config/endpoints'
import { post } from '../../service/API'
import { useSWRConfig } from 'swr'

export const Header = ({ headerHeight }) => {
  const { projectId } = useParams() as { projectId: string }
  const { mutate } = useSWRConfig()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  console.log('Header > projectId = ', projectId)

  const deleteProject = (projectId: string) => {
    const endpoint = ENDPOINTS.ADMIN.PROJECTS.deleteProject(projectId)
    setIsLoading(true)
    post(endpoint, { projectId: projectId }, 'DELETE')
      .then((result) => {
        console.log('Header > result = ', result)
        setIsLoading(false)
        if (result?.data?.type === 'NOT_DELETED') {
          alert('Project is not expired')
        } else {
          const urlKey = ENDPOINTS.PROJECTS.get + '/' + projectId
          mutate(urlKey)
        }

        return result
      })
      .catch((error) => {
        setIsLoading(false)
        console.log('Header > error = ', error)

        alert(error.toString())
      })
  }

  return (
    <div
      className="container-fluid bg-white position-relative  px-4 "
      style={{
        height: headerHeight,
        borderBottom: '1px solid rgba(0,0,0,.1)',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.04)',
      }}
    >
      <div className="row h-100 " style={{}}>
        <div className="col-12 mt-2 col-md-3 pb-0 d-flex justify-content-center justify-content-md-start align-items-center"></div>

        <div className="col-12 col-md-6 d-flex justify-content-center align-items-center ">
          {projectId
            ? HEADER_ROUTES.map((item, index) => {
                const to = item.gotoPage(projectId)

                return (
                  <NavLink to={to} end key={index} className="mx-4 text-decoration-none h-100">
                    {({ isActive }) => {
                      return isActive ? (
                        <div
                          className="h-100 d-flex align-items-center"
                          style={{ borderBottom: `2px solid ${COLOR_PRIMARY}`, userSelect: 'none', outline: 'none' }}
                        >
                          <p style={{ userSelect: 'none', outline: 'none' }}>{item.title}</p>
                        </div>
                      ) : (
                        <div
                          className="h-100 d-flex align-items-center"
                          style={{ userSelect: 'none', outline: 'none' }}
                        >
                          <p className="text-black">{item.title}</p>
                        </div>
                      )
                    }}
                  </NavLink>
                )
              })
            : null}
        </div>

        <div className="col-12 col-md-3 d-flex justify-content-end align-items-center">
          {projectId ? (
            <Popconfirm
              title="Delete the project"
              description="Are you sure to delete this project?"
              onConfirm={() => {
                deleteProject(projectId)
              }}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <Button danger loading={isLoading}>
                Delete project
              </Button>
            </Popconfirm>
          ) : null}

          {/*<Suspense fallback="...">
            <SettingsMenu></SettingsMenu>
          </Suspense>*/}
        </div>
      </div>
    </div>
  )
}

/*
const SettingsMenu = () => {
  const user = authStore((state) => state.user)
  const logout = authStore((state) => state.logout)
  const name = user?.attributes.email || ''
  const letter = user?.attributes.email ? user?.attributes.email[0] : ''

  const { projectId } = useParams()
  const { data } = useMeProjects()

  const type = projectId
    ? data.filter((user) => {
        return user.projectId._id === projectId
      })[0].type
    : ''

  const content = (
    <div className="px-2 ">
      <div className="mb-2 mt-2">
        <p className="p-mini fw-bold text-black">TYPE</p>
        <p className="">{type}</p>
      </div>

      <div className="mb-2" style={{ maxWidth: 300 }}>
        <p className="p-mini fw-bold text-black">EMAIL</p>
        <p className="text-truncate">{name}</p>
      </div>

      <Button onClick={logout} type="default" className="mt-2 w-100">
        <p className="fw-bold">Logout</p>
      </Button>
    </div>
  )

  const notificationContent = (
    <div className="px-2 " style={{ minWidth: 300 }}>
      <div className="mb-2 mt-2">
        <p className="p-small fw-bold text-black">Latest updates</p>
      </div>
      <div className="">
        <p className="p-mini fw-bold text-black">Yesterday</p>
        <p className="text-truncate">10 subjects Created </p>
        <p className="text-truncate">4 subjects Disqualified </p>
      </div>

      <div className="">
        <p className="p-mini fw-bold text-black mt-3">3 days ago</p>
        <p className="text-truncate">10 subjects Created </p>
        <p className="text-truncate">4 subjects Disqualified </p>
      </div>
    </div>
  )

  return (
    <div className="d-flex mt-2">
      <div className="d-flex noselect align-items-center">
        <p className="p-small fw-bold mt-1  ms-1 me-1">{type}</p>
        <p className="p-small opacity-50 mt-1  ms-1 text-truncate" style={{ maxWidth: 170 }}>
          {name}
        </p>
        <Popover placement="bottomRight" content={notificationContent}>
          <BellOutlined className="pointer mx-3" style={{ fontSize: '1.5rem' }} />
        </Popover>
        <Popover placement="bottomRight" content={content}>
          <div
            className="me-1 bg-primary rounded-circle p-2 d-flex justify-content-center align-items-center"
            style={{ width: 33, height: 33 }}
          >
            <button
              className="text-white "
              aria-label="Customise options"
              style={{ userSelect: 'none', outline: 'none' }}
            >
              <p className="fw-bold">{letter.toUpperCase()}</p>
            </button>
          </div>
        </Popover>

        {/!*<DownOutlined className="mt-1 opacity-50"></DownOutlined>*!/}
      </div>
    </div>
  )
}
*/
