import { get } from 'lodash'
import { FormDataType } from '../components/form/FormTypes'

export const populateConfig = (config, data) => {
  const copy = config.concat([])

  const k = copy.map((item) => {
    const copy: FormDataType = { ...item }

    // when using nested objects there is no simple way to get the nested value
    // the simple way to access would be  data[copy.id] , but since we have nested like recurence.type
    // we are using lodash
    //

    const value = get(data, copy.id)

    //
    // This is explicit logic for the phone form, since it has both
    // a phone string value and countryCode string value
    // We do it this way to handle one formhook component for two inputs
    //
    // TODO: make this more easy to spot. Maybe move it to the phone component
    /*if (copy.id === 'phone') {
      copy.value = { phone: value }
      copy.stateType = stateType
    } else {
      copy.value = value
      copy.stateType = stateType
    }*/

    copy.value = value

    return copy
  })

  return k
}
