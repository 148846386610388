import { API_URL } from '../constants'

export const ENDPOINTS = {
  PROJECTS: {
    get: API_URL + '/projects',
  },

  ADMIN: {
    CLIENTS: {
      get: API_URL + '/admin/clients',
      byProjectId: API_URL + '/admin/clients/projects',
      invite: (clientId: string) => {
        return API_URL + '/admin/clients/invite/' + clientId
      },
    },
    PARTICIPANTS: {
      get: (projectId: string) => {
        return API_URL + `/admin/projects/${projectId}/participants`
      },
      ACTION: {
        deleteUser: (projectId: string) => {
          return API_URL + `/admin/projects/${projectId}/participants/delete`
        },

        downloadCsv: (projectId: string) => {
          return API_URL + `/admin/projects/${projectId}/participants/download`
        },
      },
    },
    PROJECTS: {
      imageUpload: (projectId: string) => {
        return API_URL + `/admin/projects/${projectId}/upload`
      },
      update: (projectId: string) => {
        return API_URL + `/admin/projects/${projectId}`
      },
      updateSettings: (projectId: string) => {
        return API_URL + `/admin/projects/${projectId}/settings`
      },
      create: (projectId: string) => {
        return API_URL + `/admin/projects/${projectId}`
      },
      getDomineStatus: (projectId: string) => {
        return API_URL + `/admin/projects/${projectId}/domain-status`
      },
      deleteProject: (projectId: string) => {
        return API_URL + `/admin/projects/${projectId}/project/delete`
      },
    },
  },
}
