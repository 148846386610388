/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 12/04/2023.
 */
import React, { Suspense, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useProjectById } from '../../hooks/useProjectById'
import { getFormType } from '../../components/form/formFactory'
import { getProjectCampaign } from './projectConfig'
import { useForm } from 'react-hook-form'
import { populateConfig } from '../../util/populateConfig'
import { post } from '../../service/API'
import { openNotification } from '../../components/notificationToast'
import { Button } from 'antd'
import { ProjectDetails } from './ProjectDetails'
import { ProjectClients } from './ProjectClients'
import { PageHeader } from '../../components/display/PageHeader'
import { BlockHeader } from '../../components/display/BlockHeader'
import { SpinnerFullHeight } from '../../components/spinner/SpinnerFullHeight'
import { ENDPOINTS } from '../../config/endpoints'

export const ProjectView = () => {
  const { projectId } = useParams() as { projectId: string }

  const [loading, setLoading] = useState(false)
  const { data, isError } = useProjectById(projectId)

  console.log('ProjectView > data = ', data)
  console.log('ProjectView > isError = ', isError)
  const navigate = useNavigate()
  const formHook = useForm()
  const { handleSubmit } = formHook

  useEffect(() => {
    if (!data) {
      console.log('ProjectView > giGIGIGIGIG = ')
      navigate('/projects')
    }
  }, [data, navigate])

  if (!data) {
    return <SpinnerFullHeight></SpinnerFullHeight>
  }

  const configObj = populateConfig(getProjectCampaign(), data)

  const onSubmit = (formData, e) => {
    e.preventDefault()

    const updatedData = { ...data, ...formData }
    const updatedSendData = { ...updatedData, projectId: projectId }

    const path = ENDPOINTS.ADMIN.PROJECTS.update(projectId)

    setLoading(true)

    return post(path, updatedSendData, 'PUT')
      .then((result) => {
        //openNotification('Project updated', result.data ? result.data.message : 'Project has been updated', 'happy')
        openNotification('Project updated', 'Changes has been saved', 'happy')
        setLoading(false)
      })
      .catch((error) => {
        console.log('error = ', error)
        console.log('error --- = ', error)
        alert(error.toString())
        setLoading(false)
        //setErrorState(error)
      })
  }

  const onError = (err, k) => {
    console.error('ProjectView > err = ', err)
    console.log('ProjectView > err = ', err)
    alert(JSON.stringify(err))
  }

  return (
    <>
      <div className="container">
        <PageHeader backTo="../" title={'Project ' + data.title}>
          <div className="d-flex align-items-center"></div>
        </PageHeader>
      </div>

      <div className="container bg-white mb-4 px-5 item-shadow">
        <BlockHeader title="Project details">
          <Link className="" to="edit-details">
            <Button className="" type="primary">
              Edit project
            </Button>
          </Link>
        </BlockHeader>

        <ProjectDetails project={data}></ProjectDetails>
        {/*        <ProjectClients></ProjectClients>*/}
      </div>

      <div className="container bg-white mb-4 px-5 item-shadow">
        <BlockHeader title="Clients">
          <Link to="add-user">
            <Button className="" type="primary">
              Add user
            </Button>
          </Link>
        </BlockHeader>

        <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
          <ProjectClients></ProjectClients>
        </Suspense>
      </div>

      <div className="container bg-white px-5 mb-7 position-relative item-shadow">
        {loading ? (
          <div
            className="position-absolute w-100 h-100 "
            style={{ top: 0, left: 0, backgroundColor: 'rgba(0,0,0,.1)', zIndex: 10 }}
          >
            <SpinnerFullHeight fullHeight={true}></SpinnerFullHeight>
          </div>
        ) : null}

        <BlockHeader title="Campaign configuration"></BlockHeader>
        <form className="row" onSubmit={handleSubmit(onSubmit, onError)}>
          {configObj.map((item, index) => {
            const comp = getFormType(item, index, formHook)
            return comp
          })}

          <div className="col-12 mt-2 mb-4 ">
            <Button className="w-100 " style={{ height: 60 }} onClick={handleSubmit(onSubmit, onError)} type="primary">
              Save
            </Button>
          </div>
        </form>
      </div>
    </>
  )
}
