import { getReq } from '../service/API'
import useSWR from 'swr'
import { ENDPOINTS } from '../config/endpoints'

export const useDomaineStatus = (projectId: string) => {
  const endpoint = ENDPOINTS.ADMIN.PROJECTS.getDomineStatus(projectId)

  const p = projectId ? endpoint : null
  const { data, error, mutate } = useSWR(p, getReq, { suspense: false })

  return {
    data: !data ? data : data.data,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  }
}
