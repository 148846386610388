/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 28/07/2023.
 */
import React from 'react'
import { useDomaineStatus } from '../../hooks/useDomainStatus'
import Spinner from '../../components/spinner/Spinner'

type Props = { project: any }

const details = ['client', 'market', 'url', 'start', 'end']

export const ProjectDetails = ({ project }: Props) => {
  const { data } = useDomaineStatus(project._id)

  const comment = data?.ChangeInfo?.Comment || ''

  const status = data?.ChangeInfo?.Status
  return (
    <>
      <div className="row" style={{}}>
        <div className="col-12 mb-4 d-flex flex-wrap">
          {details.map((key, index) => {
            return <Item key={index} title={details[index]} value={project[key]}></Item>
          })}
        </div>
        {data && comment ? (
          <div className="col-12 d-flex mb-3">
            <p>{comment}</p>
            <p className="ms-2 fw-bold">{status}</p>
          </div>
        ) : (
          <div className="col-12 d-flex mb-3">
            <p className="ms-2 fw-bold">Loading DNS status</p>
            <div className="ms-5 ">
              <Spinner size={4}></Spinner>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
const Item = ({ title, value }) => {
  const theValue = title === 'start' || title === 'end' ? value.toString().split('T')[0] : value
  const newValue = title === 'url' ? 'https://' + value + '.cinema-ad.com' : theValue
  return (
    <div className="me-3 px-3 py-2 rounded-3" style={{ background: '#f3f3f3' }}>
      <p className="p-small opacity-75 text-capitalize">{title}</p>

      {title === 'url' ? (
        <a href={newValue} target="_blank" rel="noreferrer noopener">
          {newValue}
        </a>
      ) : (
        <p className=" text-truncate text-black" style={{ maxWidth: 220 }}>
          {newValue}
        </p>
      )}
    </div>
  )
}
